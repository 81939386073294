<template>
  <div id="app">
    <router-view  />
  </div>
</template>
<script>

 
import "@/assets/css/style.css";
// import "@/assets/iconfont/iconfont.css"; 
export default {
  name: 'App',
  components: {   },
  mounted() { }
}
</script>

<style>
html,body{padding:0;margin: 0;}
</style>
