import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state() {
        return {
            pageInfo: [],
            navHistory: [],
            brandInfo: {}

        }
    },
    mutations: {


        setBrandInfo: (state, brandInfo) => {
            state.brandInfo = brandInfo
        },
        
        setPageInfo: (state, item) => {
            item.name = item.name || item.text;
            state.pageInfo.push(item)
        },

        delPageInfo: state => {
            state.pageInfo.pop();
        },

        setNavHistory: (state, item) => {
            item.name = item.name || item.text;
            if (item.isHome) {
                state.navHistory = []
            }
            state.navHistory.push(item);
        },

        delNavHistory: state => {
            state.navHistory.pop()
        },



    }
})