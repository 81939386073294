import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: HomeView,
    hidden: true,
    meta: { title: '首页', icon: 'el-icon-s-home', noCache: true },
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/'),
        name: 'dashboard',
        hidden: false,
        meta: { title: '首页', icon: 'el-icon-s-home', noCache: true }

      }
    ]
  },
  {
    path: '/logIn',
    component: () => import('@/views/logIn/'),
    name: 'logIn',
    hidden: true,
    meta: { title: '登录', icon: 'el-icon-s-home', noCache: true }
  },



  // banner
  {
    path: '/banner',
    redirect: '/banner/index',
    component: HomeView,
    hidden: false,
    meta: { title: 'banner管理', icon: 'el-icon-menu', noCache: true },
    children: [
      {
        path: 'index',
        component: () => import('@/views/banner/'),
        name: 'bannerList',
        meta: { title: 'banner列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'edit',
        component: () => import('@/views/banner/edit'),
        name: 'bannerEdit',
        hidden: true,
        meta: { title: 'banner编辑', icon: 'el-icon-menu', noCache: true }
      }
    ]
  },
  // 活动
  {
    path: '/activity',
    redirect: '/activity/list',
    component: HomeView,
    hidden: false,
    meta: { title: '活动管理', icon: 'el-icon-goods', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/activity/index'),
        name: 'activity',
        hidden: false,
        meta: { title: '活动列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'edit',
        component: () => import('@/views/activity/edit.vue'),
        name: 'activityEdit',
        hidden: true,
        meta: { title: '活动编辑', icon: 'el-icon-menu', noCache: true }
      }
    ]
  },

  // 新闻
  {
    path: '/news',
    redirect: '/news/list',
    component: HomeView,
    hidden: false,
    meta: { title: '新闻管理', icon: 'el-icon-news', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/article/articleList'),
        name: 'news',
        hidden: false,
        meta: { title: '新闻列表', icon: 'el-icon-s-order', noCache: true },
        
      },
      {
        path: 'list/detail', 
        component: () => import('@/views/article/articleDetail'),
        name: 'newsDetail',
        hidden: true,
        meta: { title: '新闻编辑', icon: 'el-icon-menu', noCache: true },
        
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (!to.path.includes('logIn')) {
    if (token) {
      next()
    } else {
      next({ path: '/logIn' })
    }
  } else {

    next()
  }

})

export default router