<!--  -->
<template>
    <div class="appMin">
        <el-breadcrumb separator-class="el-icon-arrow-right" v-if="$route.meta.title != '首页'">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item, index) in $route.matched" :key="index" :to="{ path: item.path }">
                {{ item.meta.title }}
            </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="appMin_con">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'appMin',
    components: {},
    props: {},
    data() {
        return {

        };
    },

    computed: {},

    watch: {
        '$route': {
            handler(newval) { 
            },
            deep: true
        }
    },

    created() { },

    mounted() {
    },

    methods: {},
}

</script>

<style scoped>
.appMin {
    padding: 90px 20px 20px 20px;
}

.el-breadcrumb {
    padding-bottom: 14px;
    border-bottom: 1px solid #dfdfdf;
}

.appMin_con {
    padding-top: 20px;
    min-height: calc(100vh - 160px);
}
</style>